import { Link } from "react-router-dom";
import Gallery from "./gallary";
import BookTIcketIcon from "./icons/bookTicket";
import ConnectedWorldICon from "./icons/connectedWorld";
import WaveTopIcon from "./icons/waveTop";
import Populars from "./populars";
import Hero from "./hero";
import WaveBottomIcon from "./icons/waveBottom";

export default function LandingHome() {
  return (
    <>
      <div className="bg-gradient-to-b from-gray-100 to-primary-500 px-4 py-16  w-full h-4/5 md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-teal-accent-400"></div>
            <div className="max-w-xl mb-6">
              <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none">
                BEZGET
                <br className="hidden md:block" />
                TOUR &{" "}
                <span className="inline-block text-deep-purple-accent-400">
                  TRAVEL
                </span>
              </h2>
              <p className="text-base text-gray-200 md:text-lg">
                Bezget Tours is an Ethiopian Tour Operator offering a variety of
                tours that highlight the country's indigenous cultures,
                fascinating history, and natural wonders.
              </p>
            </div>
            <div className="flex flex-wrap ssm:flex-nowrap justify-center ssm:justify-normal gap-x-8 gap-y-4">
              <div className="text-center w-36 bg-secondary-300 rounded-lg">
                <Link
                  to="/contact"
                  className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Learn more
                </Link>
              </div>
              <div className="text-center w-42 bg-gray-100 rounded-lg">
                <Link
                  to="/destinations"
                  className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                >
                  Popular Places
                </Link>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center mx-4 lg:pl-8">
            <img alt="imagess" className="" src="./images/ethiopia.png" />
          </div>
        </div>
      </div>
      <div className="relative ">
        <WaveTopIcon />
      </div>
      <section className="bg-white border-b  pb-4">
        <div className="container max-w-5xl mx-auto m-8">
          <div className="w-full mb-2">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                Flight Ticket Booking
              </h3>
              <p className="text-gray-600 mb-8">
                We as a company will allow you to book your flight ticket with
                no hassel and save your precious time. Come to us and you will
                not regrete.
                <br />
                <br />
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <BookTIcketIcon />
            </div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <ConnectedWorldICon />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Business Tour & Travel
                </h3>
                <p className="text-gray-600 mb-8">
                  We provide different tour and travel services accross all over
                  the country Ethiopia. Come to us you will love our suggestion
                  and services.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-5/6 sm:w-1/2 p-6">
              <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                Car & Vehicle rental services
              </h3>
              <p className="text-gray-600 mb-8">
                Different car rental services that are suitable for your tour
                and travel. We also provede luxuries cars that with a moderate
                payment.
                <br />
                <br />
              </p>
            </div>
            <div className="w-full sm:w-1/2 p-6">
              <img alt="imagess" src="./icons/carrental.png" />
            </div>
          </div>
          <div className="flex flex-wrap flex-col-reverse sm:flex-row">
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <img alt="imagess" src="./icons/cities.png" />
            </div>
            <div className="w-full sm:w-1/2 p-6 mt-6">
              <div className="align-middle">
                <h3 className="text-3xl text-gray-800 font-bold leading-none mb-3">
                  Addis Ababa City Tour with luxuries cars
                </h3>
                <p className="text-gray-600 mb-8">
                  We have a well sturcuted team that allows you to know the
                  capital city of africe Addis Ababa. And we have a full package
                  of car services.
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-white  py-8 text-primary-900">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
          <section className=" container w-full mx-auto text-center py-6 mb-1">
            <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
              Popular Places
            </h2>
            <div className="w-full mb-4">
              <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
            </div>
            <h3 className="my-4 text-3xl leading-tight">
              Scroll horizontally to see all popular places
            </h3>
          </section>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <Populars />
        </div>
      </section>
      <div>
        <section className=" container w-full mx-auto text-center py-6 mb-1">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
            Our Gallery
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <h3 className="my-4 text-3xl leading-tight">
            We have a bunch of photo and video galleries for each place.
          </h3>
          <Link
            to="/gallery"
            className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
          >
            Find more
          </Link>
        </section>
        <Hero />
        <Gallery />
        <section className="bg-white">
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-md sm:text-center">
              <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl dark:text-white">
                Sign up for our newsletter
              </h2>
              <p className="mx-auto mb-8 max-w-2xl font-light text-gray-600 md:mb-12 sm:text-xl dark:text-gray-400">
                Stay up to date with the roadmap progress, announcements and
                exclusive discounts feel free to sign up with your email.
              </p>
              <form action="#">
                <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                  <div className="relative w-full">
                    <label
                      htmlFor="email"
                      className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Email address
                    </label>
                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                      <svg
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                      </svg>
                    </div>
                    <input
                      className="block p-3 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                      placeholder="Enter your email"
                      type="email"
                      id="email"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="py-3 px-5 w-full text-sm font-medium text-center text-gray-100 rounded-lg border cursor-pointer bg-primary-600 border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300  dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <WaveBottomIcon />
    </>
  );
}
