import React from "react";
import { Route, Routes } from "react-router-dom";
import { Footer } from "./components/footer";
import { Nav } from "./components/navbar";

import Home from "./pages/home/index";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";
import TourPackages from "./pages/tourPackages";
import Destinations from "./pages/destinations";
import DestinationDetail from "./pages/destinationDetail";
import { ScrollToTop } from "./components/backToTopButton";

function App() {
  return (
    <div className="App">
      <Nav />
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/gallery" Component={Gallery} />
        <Route path="/destinations" Component={Destinations} />
        <Route path="/destinations/:destinationId" Component={DestinationDetail} />
        <Route path="/tourPackages" Component={TourPackages} />
        <Route path="/contact" Component={Contact} />
      </Routes>
      <Footer />
      <ScrollToTop />

    </div>
  );
}

export default App;
