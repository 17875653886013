import Landing from "../../components/landing";
import Step from "../../components/tourPackages/steps";


export default function TourPackage() {
  return (
    <div className="scroll-smooth">
      <Landing title={"Explore Our Tour Packages"} subTitle={"We have a bunch of tour packages divided in categories"} image={"abay.jpg"} />
      <Step />
    </div>
  );
}
