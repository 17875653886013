import Landing from "../../components/landing";
import LargeCard from "../../components/largeCard";

export default function Destination() {
  return (
    <>
      <Landing
        title={"A list of Destination Places"}
        subTitle={"choose one and spend your time with us"}
        image={"lalibela.jpg"}
      />
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-md sm:text-center">
            <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl dark:text-white">
              We have prepared a varaiety of destination.
            </h2>
            <p className="mx-auto mb-8 max-w-2xl font-light text-gray-600 md:mb-12 sm:text-xl dark:text-gray-400">
              Choose a place of destination and contact us. We will come to
              where you are. We are always ready to serve you.
            </p>
          </div>
        </div>
      </section>
      <LargeCard />
    </>
  );
}
