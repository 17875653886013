"use client";
import { useState } from "react";
import axios from "axios";
import LaindingAll from "../../components/landing";

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const portalID = "24227707";
  const formID = "995da318-9a4f-4ef7-99ec-922e1feb1e2b";

  const handleSubmit = async () => {
    await axios({
      method: "post",
      url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalID}/${formID}`,
      data: {
        fields: [
          {
            name: "name",
            value: name,
          },
          {
            name: "email",
            value: email,
          },
          {
            name: "message",
            value: message,
          },
        ],
      },
    });
    window.alert("sent successfully");
  };

  return (
    <>
      <LaindingAll
        title={"Get intouch with us"}
        subTitle={"You can contact us with the methods listed below"}
        image={"semienmountain.jpg"}
        btnText="Contact Us"
      />

      <div className="relative bottom-[150px] grid grid-cols-1 md:grid-cols-2  bg-gray-400 max-w-[1200px] mx-auto p-8 my-16 gap-4 ">
        <div className="w-full">
          <form>
            <div className="form-group mb-6">
              <input
                type="text"
                className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-900
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-900 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput7"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group mb-6">
              <input
                type="email"
                className="form-control block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-900
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-900 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="exampleInput8"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group mb-6">
              <textarea
                className="
                  form-control
                  block
                  w-full
                  px-3
                  py-1.5
                  text-base
                  font-normal
                  text-gray-900
                  bg-white bg-clip-padding
                  border border-solid border-gray-300
                  rounded
                  transition
                  ease-in-out
                  m-0
                  focus:text-gray-900 focus:bg-white focus:border-blue-600 focus:outline-none
                "
                id="exampleFormControlTextarea13"
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>

            <div
              className="
                w-full
                px-6
                py-2.5
                bg-primary-200
                text-white
                text-center
                font-medium
                text-xs
                leading-tight
                uppercase
                rounded
                shadow-md
                hover:bg-blue-700 hover:shadow-lg
                focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
                active:bg-blue-800 active:shadow-lg
                transition
                duration-150
                ease-in-out"
              onClick={handleSubmit}
            >
              Send
            </div>
          </form>
        </div>
        <div className="w-full">
          <div className="flex flex-wrap overflow-hidden text-center md:text-start ">
            <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 px-3 lg:px-6">
              <div className="flex ">
                <div className="shrink-0"></div>
                <div className="grow ml-6">
                  <p className="font-bold mb-1">Address</p>
                  <p className="text-gray-700">Addisababa, Ethiopia</p>
                  <p className="text-gray-700">
                    Dembel city Center, 9th floor,{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-12 grow-0 shrink-0 basis-auto w-full md:w-6/12 px-3 lg:px-6">
              <div className="flex">
                <div className="shrink-0"></div>
                <div className="grow ml-6">
                  <p className="font-bold mb-1">Email</p>
                  <p className="text-gray-700">info@bezgettour.com</p>
                </div>
              </div>
            </div>
            <div className="mb-12 md:mb-0 grow-0 shrink-0 basis-auto w-full md:w-6/12 px-3 lg:px-6">
              <div className="flex">
                <div className="shrink-0"></div>
                <div className="grow ml-6">
                  <p className="font-bold mb-1">Tell</p>
                  <p className="text-gray-700">+2519</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        className="w-full h-[1000px] relative bottom-[100px] p-8 "
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.6417247092904!2d38.7674981!3d9.0050761!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85b039c25bc1%3A0x941677a8f60160fe!2sDembel%20City%20Center!5e0!3m2!1sen!2set!4v1680905559153!5m2!1sen!2set"
        loading="lazy"
      ></iframe>
    </>
  );
}
