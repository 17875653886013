export default function LaindingAll({
  title,
  subTitle,
  image,
  btnText,
}: {
  title: string;
  subTitle: string;
  image: string;
  btnText?: string;
}) {
  return (
    <div
      className="relative overflow-hidden bg-cover bg-no-repeat"
      style={{
        ["background-position" as any]: "50%",
        ["background-image" as any]: `url('./images/${image}')`,
        ["height" as any]: "600px",
      }}
    >
      <div
        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed"
        style={{ ["background-color" as any]: "rgba(0, 0, 0, 0.75)" }}
      >
        <div className="flex h-full items-center justify-center">
          <div className="px-6 text-center text-white md:px-12">
            <h1 className="mb-6 text-5xl font-bold text-gray-100">{title}</h1>
            <h3 className="mb-8 text-3xl font-bold text-gray-500">
              {subTitle}
            </h3>
            {btnText != null ? (
              <button 
                type="button"
                className="inline-block rounded border-2 border-neutral-50 px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                {btnText}
              </button>
            ) : (
              <button
                type="button"
                className="inline-block rounded border-2 border-neutral-50 px-6 pt-2 pb-[6px] text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Enjoy with us
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
