import GallarySlider from "../../components/gallarySlider";
import LaindingAll from "../../components/landing";

export default function Gallery() {
  return (
    <>
      <LaindingAll
        title={"Our Tour Galleries"}
        subTitle={
          "We have a bunch of photo and video galleries."
        }
        image={"harar.jpeg"}
      />
      <GallarySlider />
    </>
  );
}
