export default function Hero({}: {}) {
  return (
    <div className="max-w-[1200px] mx-auto">
      <div className="relative left-[6%] sm:left-[2%] w-[90%] mx-auto mt-4 h-full -translate-x-4 -rotate-2 rounded-3xl bg-gradient-to-r from-purple-400 via-blue-500 to-indigo-500 p-4">
        <div className="w-[90%] mx-auto h-full translate-x-4 rotate-6 overflow-hidden rounded-3xl shadow-lg transition-transform hover:scale-105">
          <video autoPlay muted loop className="h-full w-full object-cover">
            <source src="./videos/ethiopia.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}
