import DownArrowIcon from "../icons/downArrow";
import data from "../../models/data.json";

export default function Step() {
  return (
    <>
      {data.steps.map((item, index) => (
        <div>
          <div className="py-16 text-center">
            <div className="mb-12 md:mb-0">
              <div className="mb-6 flex justify-center">
                <img
                  alt="image"
                  src={`./images/${data.categories[index].icon}`}
                  className="w-32 rounded-full shadow-lg dark:shadow-black/30"
                />
              </div>
              <h5 className="mb-4 text-xl font-semibold">
                {data.categories[index].title}
              </h5>
              <p className="mb-4 text-neutral-600 dark:text-neutral-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  className="inline-block h-7 w-7 pr-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                </svg>
                {data.categories[index].descriptions}
              </p>
            </div>
          </div>
          {item.category.map((tour, idx) => (
            <div
              key={idx}
              className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
            >
              <h1 className="text-3xl pb-8 font-bold">{tour.place}</h1>
              <div className="grid gap-6 row-gap-10 lg:grid-cols-2">
                <div>
                  {tour.tours.map((item, index) => (
                    <div className="lg:py-6 lg:pr-16">
                      <div className="flex">
                        <div className="flex flex-col items-center mr-4">
                          <div>
                            <div className="flex items-center justify-center w-10 h-10 border rounded-full">
                              <DownArrowIcon />
                            </div>
                          </div>
                          <div className="w-px h-full bg-gray-300" />
                        </div>
                        <div className="pt-1 pb-8">
                          <p className="mb-2 text-lg font-bold">
                            Day {item.day}
                          </p>
                          <p className="text-xl pb-4">{item.title}</p>
                          <p className="text-gray-700">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="relative">
                  <img
                    alt="image"
                    className="inset-0 object-cover object-bottom w-full rounded shadow-lg h-96 lg:absolute "
                    src={`./images/${tour.images}`}
                  />
                  <p className="pt-4">{`./images/${
                    data.categories[index].place
                  }/${
                    data.gallery[data.categories[index].id].images[index]
                  }`}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}
