import {Link} from "react-router-dom";
import data from "../models/data.json";

export default function Gallery() {
  return (
    <section className=" pt-8 text-center overflow-hidden text-primary-700">
      <section className=" container w-full mx-auto text-center py-6 mb-1">
        <h2 className="w-full my-2 text-4xl font-bold leading-tight text-center text-white">
          Photo Gallery
        </h2>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <h3 className="my-4 text-3xl leading-tight">
          We have a bunch of photo and video galleries for each place.
        </h3>
        <Link
          to="/gallery"
          className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-2 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out"
        >
          Find more
        </Link>
      </section>
      <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-16">
        <div className="-m-1 flex flex-wrap md:-m-2">
          <div className="flex w-1/2 flex-wrap">
            {data.leftGallery.map(
              (photo, index) =>
                index! > 2 && (
                  <div className={photo.class} key={index}>
                    <img
                      alt="gallery"
                      className="block h-full w-full rounded-lg object-center scale-100 hover:scale-[0.9] object-cover ease-in duration-500"
                      src={`./images/${photo.image}`}
                    />
                  </div>
                )
            )}
          </div>
          <div className="flex w-1/2 flex-wrap">
            {data.rightGallery.map(
              (photo, index) =>
                index! > 2 && (
                  <div className={photo.class} key={index}>
                    <img
                      alt="gallery"
                      className="block h-full w-full rounded-lg  object-center scale-100 hover:scale-[0.9] object-cover ease-in duration-500"
                      src={`./images/${photo.image}`}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
