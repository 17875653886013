"use client";
import React, { MutableRefObject } from "react";
import {
  useKeenSlider,
  KeenSliderPlugin,
  KeenSliderInstance,
} from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import data from "../models/data.json";

function GallarySlider(
  mainRef: MutableRefObject<KeenSliderInstance | null>
): KeenSliderPlugin {
  return (slider) => {
    function removeActive() {
      slider.slides.forEach((slide) => {
        slide.classList.remove("active");
      });
    }
    function addActive(idx: number) {
      slider.slides[idx].classList.add("active");
    }

    function addClickEvents() {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener("click", () => {
          if (mainRef.current) mainRef.current.moveToIdx(idx);
        });
      });
    }

    slider.on("created", () => {
      if (!mainRef.current) return;
      addActive(slider.track.details.rel);
      addClickEvents();
      mainRef.current.on("animationStarted", (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(Math.min(slider.track.details.maxIdx, next));
      });
    });
  };
}

export default function App() {
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 4,
  });
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: 4,
        spacing: 10,
      },
    },
    [GallarySlider(instanceRef)]
  );

  return (
    <div className="flex flex-col space-y-16 max-w-[1200px] mx-auto">
      {data.gallery.map((item, index) => (
        <div className="" key={index}>
          <section className="bg-white  pt-16">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
              <div className="mx-auto max-w-screen-md sm:text-center">
                <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl dark:text-white">
                  {item.place.toUpperCase()}
                </h2>
                <p className="mx-auto mb-8 max-w-2xl font-light text-gray-600 md:mb-12 sm:text-xl dark:text-gray-400 line-clamp-3">
                  {data.destinations[index].description.slice(0, 170) + "..."}
                </p>
              </div>
            </div>
          </section>
          <div className="grid grid-cols-1 ssm:grid-cols-3 gap-2">
            {item.images.map(
              (photo, index) =>
                index < 3 && (
                  <img
                    alt="image"
                    key={index}
                    className="scale-100 hover:scale-[0.85] object-cover ease-in duration-500 h-[200px] md:h-[400px] w-full max-w-full rounded-lg"
                    src={`./images/${item.place}/${photo}`}
                  />
                )
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
